<template>
    <div
        class="container"
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
        }"
    >
        <div
            class="modal fade"
            id="bookingCancelledModal"
            tabindex="-1"
            style="z-index: 2147483647"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
        >
            <div class="modal-dialog modal-dialog-centered modal-sm">
                <div class="modal-content">
                    <div class="modal-body text-center" v-if="booking">
                        <p style="font-weight: 500" class="mb-2">
                            {{ t('pages.bookings.cancelled') }}
                        </p>
                        {{ t('pages.bookings.yourBookingHasBeenCancelled') }}
                    </div>
                    <a
                        @click="router.push('/bookings')"
                        class="border-top text-center py-2 tap-react rounded-bottom"
                        data-bs-dismiss="modal"
                        style="font-weight: 600"
                    >
                        OK
                    </a>
                </div>
            </div>
        </div>
        <div
            class="modal fade"
            id="cannotCancelModal"
            tabindex="-1"
            style="z-index: 2147483647"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
        >
            <div class="modal-dialog modal-dialog-centered modal-sm">
                <div class="modal-content">
                    <div class="modal-body text-center" v-if="booking">
                        <p style="font-weight: 500" class="mb-2">
                            {{ t('pages.bookings.cannotCancelTitle') }}
                        </p>
                        {{
                            t('pages.bookings.afterDeadline', {
                                hours: bookingSettings.cancellation_deadline_hours,
                            })
                        }}
                    </div>
                    <a
                        class="border-top text-center py-2 tap-react rounded-bottom"
                        data-bs-dismiss="modal"
                        style="font-weight: 600"
                    >
                        OK
                    </a>
                </div>
            </div>
        </div>
        <div
            class="modal fade"
            id="cancelBookingModal"
            tabindex="-1"
            style="z-index: 1000000"
        >
            <div class="modal-dialog modal-dialog-centered modal-sm">
                <div class="modal-content">
                    <div class="modal-body text-center">
                        <p style="font-weight: 500" class="mb-2">
                            {{ t('pages.bookings.areYouSureCancel') }}
                        </p>
                        <span v-if="isOneHourAfterDeparture">
                            {{
                                t(
                                    'pages.bookings.daysNotRefundedOneHourAfterDeparture'
                                )
                            }}
                        </span>
                        <span v-else>
                            <span v-if="isAfterCancellationDeadline">
                                {{
                                    t('pages.bookings.afterDeadline', {
                                        hours: bookingSettings.cancellation_deadline_hours,
                                    })
                                }}. {{ t('pages.bookings.willNotGetDaysBack') }}
                            </span>
                            <span v-else>
                                {{ t('pages.bookings.cannotReverse') }}<br />
                                <span v-if="booking">
                                    <span
                                        v-if="
                                            booking.booking.tokens_duducted == 1
                                        "
                                    >
                                        {{
                                            t('pages.bookings.singularToken', {
                                                tokens: booking.booking
                                                    .tokens_deducted,
                                            })
                                        }}
                                    </span>
                                    <span v-else>
                                        {{
                                            t('pages.bookings.multipleTokens', {
                                                tokens: booking.booking
                                                    .tokens_deducted,
                                            })
                                        }}
                                    </span>
                                </span>
                            </span>
                        </span>
                        <div class="form-check text-start mt-3">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="cancelDueToBadWeather"
                                v-model="cancelDueToBadWeather"
                            />
                            <label
                                class="form-check-label"
                                for="cancelDueToBadWeather"
                            >
                                {{
                                    t(
                                        'pages.bookings.iAmCancellingDueToBadWeather'
                                    )
                                }}
                            </label>
                        </div>
                        <textarea
                            id="reason"
                            class="form-control mt-3"
                            style="min-height: 5rem"
                            v-model="cancellationReason"
                            :placeholder="t('pages.bookings.reasonPlaceholder')"
                        ></textarea>
                    </div>
                    <div class="border-top">
                        <div class="d-flex flex-row px-0">
                            <a
                                @click="cancelBooking"
                                data-bs-dismiss="modal"
                                class="text-danger w-50 border-end py-2 tap-react rounded-bottom"
                                style="font-weight: 500"
                            >
                                <div class="col text-center">
                                    {{ t('pages.bookings.yes') }}
                                </div>
                            </a>
                            <a
                                data-bs-dismiss="modal"
                                class="w-50 py-2 tap-react rounded-bottom"
                                style="font-weight: 600"
                            >
                                <div class="col text-center">
                                    {{ t('pages.bookings.no') }}
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-row align-items-center mb-3">
            <BackButton />
            <h1 class="text-light m-0">
                {{ t('pages.bookings.booking') }}
                <span v-if="booking">
                    <span v-if="yachts">
                        <span v-if="getYacht(booking.booking).brand">
                            {{ getYacht(booking.booking).brand.name }}
                        </span>
                        {{ getYacht(booking.booking).identity.name }}
                    </span>
                    <span v-if="booking.bookingType">
                        —
                        <span v-if="$i18n.locale == 'fr'">
                            {{ booking.bookingType.name_fr }}
                        </span>
                        <span v-else>
                            {{ booking.bookingType.name_en }}
                        </span>
                    </span>
                </span>
            </h1>
        </div>
        <div class="card shadow-sm p-3">
            <div v-if="!bookingFound">
                <h5>{{ t('pages.bookings.cantFindBooking') }}</h5>
                <p>
                    {{ t('pages.bookings.cantFindBookingDesc') }}
                </p>
                <router-link to="/bookings" class="btn btn-primary w-100">{{
                    t('misc.goBack')
                }}</router-link>
            </div>
            <div v-if="!cancellationInProgress">
                <div v-if="booking">
                    <router-link :to="`/yachts/${booking.booking.boat_id}`">
                        <YachtItem
                            width="100%"
                            :hide-text="true"
                            class="mb-3"
                            :yacht="getYacht(booking.booking)"
                        />
                    </router-link>
                    <div
                        class="d-flex flex-row align-items-center mb-1"
                        style="gap: 1rem"
                    >
                        <div
                            style="width: 160px; font-weight: 500"
                            class="text-muted"
                        >
                            {{ t('pages.bookings.bookingID') }}
                        </div>
                        {{ '#' + booking.booking.id }}
                    </div>
                    <div
                        class="d-flex flex-row align-items-center mb-1"
                        style="gap: 1rem"
                    >
                        <div
                            style="width: 160px; font-weight: 500"
                            class="text-muted"
                        >
                            {{ t('pages.bookings.bookingCreated') }}
                        </div>
                        <div>
                            {{
                                moment
                                    .utc(booking.booking.created_at)
                                    .tz('Europe/Paris')
                                    .format('MMM Do YYYY, HH:mm')
                            }}
                        </div>
                    </div>
                    <div
                        v-if="booking.bookingType"
                        class="d-flex flex-row align-items-center mb-1"
                        style="gap: 1rem"
                    >
                        <div
                            style="width: 160px; font-weight: 500"
                            class="text-muted"
                        >
                            {{ t('pages.bookings.bookingType') }}
                        </div>
                        <div>
                            <span v-if="$i18n.locale == 'fr'">
                                {{ booking.bookingType.name_fr }}
                            </span>
                            <span v-else>
                                {{ booking.bookingType.name_en }}
                            </span>
                        </div>
                    </div>
                    <p
                        class="mt-0 mb-0 text-muted mb-2"
                        style="font-weight: 500"
                    >
                        {{ t('pages.bookings.bookingInOut') }}
                    </p>
                    <div
                        class="d-flex flex-row justify-content-between align-items-center mb-1 mt-0"
                    >
                        <div
                            class="d-flex flex-row justify-content-between align-items-center"
                        >
                            <!-- Icons -->
                            <div
                                class="d-flex flex-column text-muted align-items-center"
                                style="gap: 1.3rem; z-index: 100"
                            >
                                <i
                                    class="dot-icon fa fa-circle py-1"
                                    style="background: white"
                                ></i>
                                <i
                                    class="dot-icon fa fa-circle py-1 pb-2"
                                    style="background: white"
                                ></i>
                            </div>

                            <!-- Content -->
                            <div
                                class="d-flex flex-column align-items-start"
                                style="
                                    gap: 0.5rem;
                                    padding-left: 1rem;
                                    margin-left: -0.35rem;
                                    border-left: 2px solid #cbcbcb;
                                "
                            >
                                <div>
                                    {{
                                        moment
                                            .utc(
                                                booking.booking.start_timestamp
                                            )
                                            .format('MMM Do YYYY, HH:mm')
                                    }}
                                </div>
                                <div>
                                    {{
                                        moment
                                            .utc(booking.booking.end_timestamp)
                                            .format('MMM Do YYYY, HH:mm')
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="booking.captains && booking.captains.length"
                        class="my-4"
                    >
                        <router-link
                            v-for="captain in booking.captains"
                            :key="'captain' + captain.id"
                            :to="`/messages/conversation/${getConversationId(
                                captain.id
                            )}`"
                            style="color: inherit"
                        >
                            <div
                                style="gap: 0.5rem; color: inherit"
                                class="d-flex flex-row align-items-center border rounded p-2 tap-react my-2"
                            >
                                <img
                                    :src="captainProfilePicture(captain)"
                                    class="profile-picture"
                                />
                                <div>
                                    <h6 class="m-0">
                                        {{ t('pages.bookings.captain') }}
                                        {{ captain.last_name }}
                                    </h6>
                                    <p class="text-muted m-0">
                                        {{
                                            t('pages.bookings.tapToSendMessage')
                                        }}
                                        <i
                                            class="ms-1 fa fa-chevron-right"
                                            style="font-size: 0.8rem"
                                        ></i>
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div v-else class="border rounded p-2 my-3">
                        <i class="fa fa-question-circle me-1"></i>
                        {{ t('pages.bookings.captainNotFound') }}
                    </div>
                    <div
                        class="d-flex flex-row align-items-center mb-1"
                        style="gap: 1rem"
                    >
                        <div
                            style="width: 160px; font-weight: 500"
                            class="text-muted"
                        >
                            {{ t('pages.bookings.status') }}
                        </div>
                        <span
                            v-if="booking"
                            class="badge bg-secondary text-light"
                            :class="diff(booking.booking).classes"
                        >
                            <span
                                v-if="diff(booking.booking).code == 'cancelled'"
                            >
                                {{ t('pages.bookings.cancelled') }}
                            </span>
                            <span v-else>
                                {{ diff(booking.booking).status }}
                                {{ diff(booking.booking).text }}
                            </span>
                        </span>
                    </div>
                    <div
                        class="d-flex flex-row align-items-center mb-1"
                        style="gap: 1rem"
                    >
                        <div
                            style="width: 160px; font-weight: 500"
                            class="text-muted"
                        >
                            {{ t('pages.bookings.tokensUsed') }}
                        </div>
                        <img src="@/assets/coin-icon.png" style="width: 1rem" />
                        {{ booking.booking.tokens_deducted }}
                    </div>
                    <div
                        v-if="diff(booking.booking).code != 'active'"
                        class="d-flex flex-row align-items-center mb-1"
                        style="gap: 1rem"
                    >
                        <div
                            style="width: 160px; font-weight: 500"
                            class="text-muted"
                        >
                            {{ t('pages.bookings.cancelDeadline') }}
                        </div>
                        {{
                            convertToDaysOrHours(
                                bookingSettings.cancellation_deadline_hours
                            )
                        }}
                    </div>
                    <div>
                        <label
                            for="notes"
                            class="form-label mb-0 text-muted"
                            style="font-weight: 500"
                            >{{
                                t('pages.bookings.notesAndSpecialRequests')
                            }}</label
                        >
                        <textarea
                            v-if="
                                booking.booking.notes &&
                                booking.booking.notes != ''
                            "
                            readonly
                            disabled
                            id="notes"
                            class="form-control"
                            v-model="booking.booking.notes"
                        ></textarea>
                        <div v-else>
                            {{ t('pages.bookings.didNotProvide') }}
                        </div>
                    </div>
                </div>
                <div
                    class="d-flex flex-row justify-content-end"
                    v-if="
                        booking &&
                        diff(booking.booking).code != 'cancelled' &&
                        diff(booking.booking).code != 'ended'
                    "
                >
                    <a
                        href="#"
                        class="d-inline-block text-danger mt-4"
                        @click="validateCancellation"
                    >
                        {{ t('pages.bookings.cancelBooking') }}
                    </a>
                </div>
            </div>
            <div
                class="d-flex flex-column justify-content-center align-items-center py-4"
                style="gap: 0.5rem"
                v-else
            >
                <img
                    src="@/assets/loading-icon.gif"
                    style="width: 1.5rem; height: 1.5rem"
                />
                {{ t('pages.bookings.cancellationInProgress') }}
            </div>
            <button
                data-bs-target="#cancelBookingModal"
                data-bs-toggle="modal"
                ref="toggleCancelBookingModal"
                class="d-none"
            ></button>
            <button
                data-bs-target="#cannotCancelModal"
                data-bs-toggle="modal"
                ref="toggleCannotCancelModal"
                class="d-none"
            ></button>
            <button
                data-bs-target="#bookingCancelledModal"
                data-bs-toggle="modal"
                ref="toggleBookingCancelledModal"
                class="d-none"
            ></button>
        </div>
    </div>
</template>

<script setup>
import axios from 'axios';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import { inject, onMounted, ref } from 'vue';

import { useYachtsStore } from '@/stores/yachts-store';
import { useMessageStore } from '@/stores/messages-store';
import { useBookingsStore } from '@/stores/bookings-store';
import { useBookingSettingsStore } from '@/stores/booking-settings-store';

import BackButton from '@/components/BackButton';
import YachtItem from '@/components/YachtItem';
import placeholderProfilePicture from '@/assets/user-placeholder.png';

const router = useRouter();
const moment = inject('moment');
const route = useRoute();
const { t } = useI18n();

const toggleCancelBookingModal = ref(null);
const toggleCannotCancelModal = ref(null);
const toggleBookingCancelledModal = ref(null);

const cancellationReason = ref('');
const cancellationInProgress = ref(false);
const cancelDueToBadWeather = ref(false);

const GLOBAL_VAR_BACKEND_STORAGE_URL = inject('GLOBAL_VAR_BACKEND_STORAGE_URL');
const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');
const booking = ref(null);

const bookingSettingsStore = useBookingSettingsStore();
const { bookingSettings } = storeToRefs(bookingSettingsStore);

const yachtsStore = useYachtsStore();
const { yachts } = storeToRefs(yachtsStore);

const bookingsStore = useBookingsStore();
const { activeBookings, expiredBookings } = storeToRefs(bookingsStore);

const isAfterCancellationDeadline = ref(false);
const isOneHourAfterDeparture = ref(false);

const bookingFound = ref(true);

function getYacht(booking) {
    const yacht = yachts.value.find((yacht) => {
        return yacht.itself.id == booking.boat_id;
    });
    return yacht;
}

async function cancelBooking() {
    try {
        cancellationInProgress.value = true;
        await axios.post(`bookings/cancel/${booking.value.booking.id}`, {
            reason: cancellationReason.value,
            bad_weather: cancelDueToBadWeather.value,
        });
        cancellationInProgress.value = false;
        toggleBookingCancelledModal.value.click();
    } catch (e) {
        cancellationInProgress.value = false;
        alert(t('pages.bookings.cancelError'));
    }
}

function convertToDaysOrHours(hours) {
    let displayDays = null;
    let displayHours = null;

    if (hours % 24 == 0) {
        displayDays = hours / 24;
    } else {
        let rawDays = hours / 24;
        displayDays = Math.floor(rawDays);
        displayHours = Math.floor((rawDays - displayDays) * 24);
    }

    let display = '';
    if (displayDays) {
        display += `${displayDays} ${t('pages.bookNow.slotSelector.day')}`;
        if (displayDays != 1) {
            display += 's ';
        }
        display += ' ';
    }
    if (displayHours) {
        display += `${displayHours} ${t('pages.bookNow.slotSelector.hour')}`;
        if (displayHours != 1) {
            display += 's';
        }
    }
    return display;
}

function captainProfilePicture(user) {
    if (user.passport_photo == null || user.passport_photo == '')
        return placeholderProfilePicture;
    return GLOBAL_VAR_BACKEND_STORAGE_URL + user.passport_photo;
}

function diff(booking) {
    const rawCheckIn = moment.utc(booking.start_timestamp);
    const rawCheckOut = moment.utc(booking.end_timestamp);
    const checkIn = moment(
        rawCheckIn.format('YYYY-MM-DD') + ' ' + rawCheckIn.format('HH:mm')
    );
    const checkOut = moment(
        rawCheckOut.format('YYYY-MM-DD') + ' ' + rawCheckOut.format('HH:mm')
    );
    const now = moment();

    const startDiff = checkIn.diff(now, 'hours', true);
    const endDiff = checkOut.diff(now, 'hours', true);
    const startsInText = checkIn.fromNow();
    const endsInText = checkOut.fromNow();

    if (booking.cancelled) {
        return {
            text: 'Cancelled',
            status: 'cancelled',
            code: 'cancelled',
            classes: 'bg-danger text-light',
        };
    }

    if (endDiff <= 0) {
        // booking ended
        return {
            text: endsInText,
            status: t('pages.bookings.ended'),
            code: 'ended',
            classes: 'bg-warning text-dark',
        };
    } else if (startDiff <= 0 && endDiff >= 0) {
        // booking active
        return {
            text: endsInText,
            status: t('pages.bookings.active'),
            code: 'active',
            classes: 'bg-success text-light',
        };
    }
    if (endDiff > 0 && startDiff > 0) {
        // booking hasn't started yet
        return {
            text: startsInText,
            status: t('pages.bookings.starts'),
            code: 'waiting',
            classes: 'bg-secondary text-light',
        };
    }
}

const getConversationId = (captainId) => {
    const messagesStore = useMessageStore();
    const { conversations } = storeToRefs(messagesStore);
    return conversations.value.findIndex((conversation) => {
        return conversation.person.id == captainId;
    });
};

function validateCancellation() {
    const now = moment();
    const raw = moment.utc(booking.value.booking.start_timestamp);
    const start = moment(raw.format('YYYY-MM-DD') + ' ' + raw.format('HH:mm')); // this removes the timezone
    const diffStartAndNow = start.diff(now, 'hours', true);

    if (diffStartAndNow < bookingSettings.value.cancellation_deadline_hours) {
        // toggleCannotCancelModal.value.click();
        isAfterCancellationDeadline.value = true;
    } else {
        isAfterCancellationDeadline.value = false;
    }

    console.log('diffStartAndNow', diffStartAndNow);
    // Booking has already started
    if (diffStartAndNow < 0) {
        // After one hour of original departure
        if (Math.abs(diffStartAndNow) > 1) {
            isOneHourAfterDeparture.value = true;
        } else {
            isOneHourAfterDeparture.value = false;
        }
    } else {
        isOneHourAfterDeparture.value = false;
    }
    console.log({
        isAfterCancellationDeadline: isAfterCancellationDeadline.value,
        isOneHourAfterDeparture: isOneHourAfterDeparture.value,
    });
    toggleCancelBookingModal.value.click();
}

async function fetchBooking() {
    bookingsStore.fetchBookings();

    // First we find the bookings in the active section
    booking.value = activeBookings.value.find((booking) => {
        return booking.booking.id == route.params.id;
    });

    // If can't find active bookings, find expired
    if (!booking.value) {
        booking.value = expiredBookings.value.find((booking) => {
            return booking.booking.id == route.params.id;
        });

        if (!booking.value) {
            bookingFound.value = false;
        }
    }
}

onMounted(() => {
    fetchBooking();
    bookingSettingsStore.fetch();
});
</script>

<style scoped>
.line::after {
    content: '';
    background-color: black;
    width: 1px;
    height: 50px;
    position: absolute;
    left: 0;
}

.line-container {
    position: relative;
}

.arrow-icon {
    font-size: 0.8rem;
}

.dot-icon {
    font-size: 0.5rem;
}

.profile-picture {
    object-fit: cover;
    width: 3rem;
    height: 3rem;
    margin: 0;
    border-radius: 100%;
}
</style>
