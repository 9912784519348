import { defineStore } from 'pinia';
import axios from 'axios';

export const useBookingSettingsStore = defineStore('bookingSettings', {
    state: () => ({
        bookingSettings: null,
        loadingBookingSettings: true,
    }),
    actions: {
        async fetch() {
            this.$state.loadingBookingSettings = true;
            axios.get('/schedule/settings').then(
                (res) => {
                    this.$state.bookingSettings = res.data;
                    this.$state.loadingBookingSettings = false;
                },
                (e) => {
                    console.error(e);
                    this.$state.loadingBookingSettings = false;
                }
            );
        },
        clear() {
            this.$reset();
        },
    },
    persist: true,
});
