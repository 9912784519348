import { defineStore } from 'pinia';
import axios from 'axios';

export const useBookingsStore = defineStore('bookings', {
    state: () => ({
        activeBookings: [],
        expiredBookings: [],
        loadingBookings: false,
    }),
    actions: {
        async fetchBookings() {
            this.$state.loadingBookings = true;
            const res = await axios.get('bookings');
            const bookings = res.data;

            this.$state.expiredBookings = bookings.filter((booking) => {
                return booking.booking.expired || booking.booking.cancelled;
            });

            this.$state.activeBookings = bookings.filter((booking) => {
                return !booking.booking.expired && !booking.booking.cancelled;
            });

            this.$state.loadingBookings = false;
        },
        clearBookings() {
            this.$reset();
        },
    },
    persist: true,
});
