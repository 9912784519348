<template>
    <div
        v-if="yacht"
        class="yacht-item"
        :class="[yacht.canBook ? '' : 'grayscale']"
        :style="{ width: props.width }"
    >
        <!-- If there is main image, show the image-->
        <img
            v-if="yacht.mainImage"
            class="img rounded mb-3"
            :src="GLOBAL_VAR_BACKEND_STORAGE_URL + yacht.mainImage.filename"
        />
        <!-- If there is no main image, show placeholder -->
        <img
            v-if="!yacht.mainImage"
            class="img rounded mb-3"
            :src="
                GLOBAL_VAR_BACKEND_STORAGE_URL +
                '/static-img/boat-placeholder.png'
            "
        />
        <div v-if="!props.hideText">
            <h5 class="text-uppercase">
                <span v-if="yacht.brand">
                    {{ yacht.brand.name }}
                </span>
                {{ yacht.identity.name }}
            </h5>
            <span class="text-dark">
                <div
                    v-if="yacht.mainBase"
                    class="d-inline-block me-3 yacht-item-desc"
                >
                    <img
                        src="@/assets/yacht-desc-icons/port.png"
                        style="width: 1.1rem"
                        class="me-1"
                    /><span class="text-upper">{{ yacht.mainBase.name }}</span>
                </div>
                <div
                    v-if="yacht.sizeAndWeight.length"
                    class="d-inline-block me-3 yacht-item-desc"
                >
                    <img
                        src="@/assets/yacht-desc-icons/tenet.svg"
                        class="me-1"
                    />{{ yacht.sizeAndWeight.length }}
                </div>
                <div
                    v-if="yacht.identity.cabins"
                    class="d-inline-block me-3 yacht-item-desc"
                >
                    <img
                        src="@/assets/yacht-desc-icons/beds.svg"
                        class="me-1"
                    />{{ yacht.identity.cabins }}
                    {{ t('yachtItem.cabins') }}
                </div>
                <div
                    v-if="yacht.identity.passengers"
                    class="d-inline-block yacht-item-desc me-3"
                >
                    <img
                        src="@/assets/yacht-desc-icons/guests.svg"
                        class="me-1"
                    />{{ yacht.identity.passengers }}
                    {{ t('yachtItem.passengers') }}
                </div>
            </span>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, inject } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const GLOBAL_VAR_BACKEND_STORAGE_URL = inject('GLOBAL_VAR_BACKEND_STORAGE_URL');
const props = defineProps(['yacht', 'width', 'hideText']);
const yacht = ref(props.yacht);
</script>

<style scoped>
.grayscale {
    filter: grayscale(100%);
}
.yacht-item:hover {
    transform: scale(0.95) !important;
}

/* For phones and smaller */
@media (max-width: 575.98px) {
    .yacht-item {
        width: 80vw;
    }
}

/* Medium devices and bigger (tablets, less than 992px) */
@media (min-width: 575.98px) {
    .yacht-item {
        width: 300px;
    }
}

.yacht-item {
    transition-property: transform;
    transition-duration: 0.5s;
}

.yacht-item-desc img {
    width: 1.3rem;
}

.yacht-item-desc {
    font-size: 0.9rem;
}

.img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

h5 {
    word-wrap: break-word;
}
</style>
